<omt-dialog-host [title]="title" (closeClicked)="closeModal()">
  <div dialog-content fxLayout="column" fxLayoutGap="11px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25">Name</label>
      <div fxFlex="75">
        <input type="text"
               class="form-control redesign m-b-5"
               [(ngModel)]="paymentConditionModel.name"
               [placeholder]="'MANAGEMENT.TERM_NAME' | translate"
               (keyup.enter)="save()"/>
        <small class="f-s-12 text-grey-darker">{{ 'MANAGEMENT.ENTER_NAME_OF_TERM' | translate }}</small>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25">{{ 'CUSTOMER.DESCRIPTION' | translate }}</label>

      <div fxFlex="75">
        <textarea class="redesign w-100-p" [(ngModel)]="paymentConditionModel.description" [placeholder]="'CUSTOMER.DESCRIPTION' | translate"></textarea>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25">{{ 'GLOBAL.CASHBACK' | translate }}</label>
      <div fxFlex="25" class="input-wrapper" data-symbol="%">
        <input #cashback type="number" class="form-control redesign m-b-5" [(ngModel)]="paymentConditionModel.cashback" [placeholder]="'GLOBAL.CASHBACK' | translate"/>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25">{{ 'GLOBAL.CASHBACK_PERIOD' | translate }}</label>
      <div fxFlex="25" class="input-wrapper" data-symbol="Tage">
        <input type="number" class="form-control redesign m-b-5" [(ngModel)]="paymentConditionModel.cashbackWindow" [disabled]="!cashback.value"
               [placeholder]="'GLOBAL.CASHBACK_PERIOD' | translate"/>
      </div>
    </div>
  </div>

  <div dialog-footer>
    <button mat-raised-button color="primary" type="button" (click)="save()">{{ 'GLOBAL.SAVE' | translate }}</button>
  </div>
</omt-dialog-host>
